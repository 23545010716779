<template>
    <div class="animated fadeIn">
        <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />

        <b-card v-else>
            <template v-slot:header>
                <h5>Pengiriman</h5>
            </template>
            <p>
                Info! Hanya dapat <span class="text-danger">diedit</span> maksimal pada hari yang sama. setalah itu
                dinyatakan sudah fix.
            </p>
            <b-row>
                <b-col md="6">
                    <b-form-group label="Filter" label-for="filterInput" class="form-inline form-group-wrapper mb-3">
                        <div class="d-flex search-input-wrapper">
                            <b-form-input class="ml-2 mr-2" v-model="filter" name="filterTable" id="filterInput"
                                type="search" placeholder="Type to search"></b-form-input>
                            <b-button class="mr-2" variant="pgiBtn" name="searchTableBtn" :disabled="loading == true"
                                @click="onFilter(filter)">
                                <i class="fa fa-search"></i>
                            </b-button>
                            <b-button variant="pgiBtn" name="resetTableBtn" :disabled="loading == true"
                                @click="onReset()">Reset</b-button>
                        </div>
                    </b-form-group>
                    <b-form-group class="form-inline filter-form-date" label-for="filterDate" label="Periode ">
                        <div class="d-flex search-input-wrapper">
                            <b-form-input v-model="input.filterFromDateTarik" name="filterFromDateTarik" id="filterDate"
                                type="date"></b-form-input>
                            <span class="ml-2 mr-2 d-flex align-items-center">s/d</span>
                            <b-form-input v-model="input.filterToDateTarik" name="filterToDateTarik"
                                type="date"></b-form-input>
                        </div>
                    </b-form-group>

                </b-col>
                <b-col>
                    <div class="d-flex justify-content-end">
                        <b-button class="" variant="pgiBtn" @click="$bvModal.show('m-create')" :disabled="loading == true">
                            <i class="fa fa-plus"></i> Tambah Pengiriman
                        </b-button>
                    </div>
                    <div class="d-flex justify-content-end py-2">
                    </div>
                </b-col>
            </b-row>
            <b-table bordered hover show-empty :busy="isBusy" :items="pengiriman" :fields="fields" :perPage="perPage"
                responsive class="mb-3">
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Loading data...</strong>
                    </div>
                </template>
                <template v-slot:empty>
                    Tidak ada data pengiriman.
                </template>
                <template v-slot:cell(flag)="row">
                    <b-badge variant="secondary" v-if="row.item.flag == 0">Belum Diproses</b-badge>
                    <b-badge variant="info" v-else-if="row.item.flag == 1">Diproses</b-badge>
                    <b-badge variant="success" v-else-if="row.item.flag == 3">Diterima</b-badge>
                    <b-badge variant="danger" v-else-if="row.item.flag == 4">Selisih Barang</b-badge>
                </template>
                <template v-slot:cell(status_approval)="row">
                    <b-badge variant="secondary" v-if="row.item.status_approval == 0">menunguu approve</b-badge>
                    <b-badge variant="success" v-else-if="row.item.status_approval == 1">sudah diapprove</b-badge>
                </template>
                <template v-slot:cell(action)="row">
                    <div class="no-wrap">
                        <b-link :to="`${$route.path}/${row.item.id}/detail`">
                            <b-button size="sm" class="mr-3 table-button view" v-b-tooltip.hover title="Detail"
                                name="pengirimanViewBtn">
                                <i class="fa fa-info"></i>
                            </b-button>
                        </b-link>
                        <b-button size="sm" class="mr-3 table-button view" v-b-tooltip.hover title="Print"
                            name="pengirimanViewBtn">
                            <i class="fa fa-print"></i>
                        </b-button>
                    </div>
                </template>
            </b-table>

            <div class="pagination-wrapper d-flex align-items-center justify-content-between">
                <span class="ml-2">{{ totalViewText }}</span>
                <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    aria-controls="my-table" @input="onPageChange"></b-pagination>
            </div>
        </b-card>


        <!-- MODAL CREATE -->
        <b-modal id="m-create" size="lg" class="modal-lg" title="Formulir Pengiriman" no-close-on-backdrop no-close-on-esc
            hide-footer>
            <b-row>
                <b-col>
                    <b-form-group label="Kategori" label-for="filterInput">
                        <b-form-select v-model="form.kategori" id="filterStatus" name="status" value-field="id"
                            text-field="name">
                            <template v-slot:first>
                                <b-form-select-option :value="null" disabled>pilih kategori</b-form-select-option>
                            </template>
                            <b-form-select-option :value="1">Kirim Barang</b-form-select-option>
                            <b-form-select-option :value="2">Pengembalian Barang</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Pengirim" label-for="filterInput">
                        <multiselect v-model="form.pengirim" :options="pengirimOpt" :internal-search="true"
                            :allow-empty="false" deselect-label="" select-label="" label="name" track-by="kode"
                            placeholder="-- Pilih Pengirim --" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-form-group label="Lokasi" label-for="filterInput">
                        <b-form-select v-model="form.lokasi" id="filterStatus" name="status" value-field="id"
                            text-field="name" :disabled="form.kategori == null ? true : false">
                            <template v-slot:first>
                                <b-form-select-option :value="null" disabled>--- Pilih Lokasi ---</b-form-select-option>
                            </template>
                            <b-form-select-option v-for="lokasi in lokasiOpt" :value="lokasi.id">{{ lokasi.name
                            }}</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Penerima" label-for="filterInput">
                        <multiselect v-model="form.penerima" :options="penerimaOpt" :internal-search="true"
                            :allow-empty="false" deselect-label="" select-label="" label="name" track-by="kode"
                            placeholder="-- Pilih Penerima --" :disabled="form.lokasi == null ? true : false" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Kurir" label-for="filterInput">
                <multiselect v-model="valueKurir" :options="kurirOpt" :internal-search="true" :allow-empty="false"
                    deselect-label="" select-label="" label="name" track-by="value" placeholder="-- Pilih Kurir --" />
            </b-form-group>

            <div v-if="valueKurir != null">
                <b-form-group v-if="valueKurir.value == 1 || valueKurir.value == 2">
                    <label for="kurir">Nama Kurir</label>
                    <b-form-input v-model="form.kurir" id="kurir" type="text"></b-form-input>
                </b-form-group>
            </div>

            <b-form-group>
                <label for="keterangan">Keterangan</label>
                <b-form-textarea v-model="form.keterangan" id="keterangan" :rows="9"
                    placeholder="Content.."></b-form-textarea>
            </b-form-group>

            <div class="mt-4 text-right">
                <b-button variant="pgiBtn" @click="prepare('m-confirm-create')">
                    Simpan
                </b-button>
            </div>
        </b-modal>
        <ModalConfirm id="m-confirm-create" title="Confirm Tambah Pengiriman" :isSubmitting="isSubmitting"
            desc="Apakah anda yakin untuk menambah Pengiriman ini ?" @submit="onSubmit" />

    </div>
</template>

<script>
export default {
    name: 'pengiriman',
    data() {
        return {
            initStatus: 0,
            isBusy: true,
            input: {
                show: 10
            },
            roleId: 0,
            form: {
                pengirim: null,
                lokasi: null,
                penerima: null,
                kurir: null,
                kategori: null,
                flag: 0,
                status: 1,
                keterangan: null,
            },

            valueKurir: null,

            lokasiOpt: null,
            pengirimOpt: null,
            penerimaOpt: [],
            kurirOpt: [
                { value: 1, name: "kurir cabang" },
                { value: 2, name: "Driver pusat" },
                { value: 3, name: "Tokopedia", $isDisabled: true },
                { value: 4, name: "Sentral kargo", $isDisabled: true },
                { value: 5, name: "JNE", $isDisabled: true }
            ],
            loading: false,
            isSubmitting: false,
            fields: [
                // { key: "id", label: "No", sortable: true },
                { key: "noInvoice", label: "No Invoice", },
                { key: "tanggal", label: "Tanggal" },
                { key: "pengirim", label: "Pengirim" },
                { key: "penerima", label: "Penerima" },
                { key: "totalBarang", label: "Total Barang" },
                { key: "flag", label: "Status" },
                { key: "status_approval", label: "Approval" },
                { key: "action", label: "Aksi" },
            ],
            filter: null,
            options: [
                { value: 10, text: '10' },
                { value: 20, text: '20' },
                { value: 30, text: '30' },
                { value: 40, text: '40' },
                { value: 50, text: '50' },
            ],
            pengirimanAll: [],
            pengiriman: [],

            currentPage: 1,
            perPage: null,
            rows: null,
            totalViewText: "",
            page: '',
            size: '',
            from: '',
            limit: '',
            loading: false,
            query: "",
        }
    },
    methods: {

        //----------------- Fungsi Inisialisasi -----------------

        init() {
            this.initStatus = 0
            this.roleId = localStorage.getItem("role_id")

            if (this.roleId == 56 || this.roleId == 5 || this.roleId == 4) {
                let getCabang = localStorage.getItem("cabang")
                this.pengirimOpt = JSON.parse(getCabang)
            } else {
                this.pengirimOpt = [
                    { kode: "0999", name: "Gudang Pusat" },
                    { kode: "P00002", name: "Toilet Lt2" },
                    { kode: "P00003", name: "Toilet Lt3" },
                    { kode: "P00004", name: "Toilet Lt4" },
                    { kode: "P00005", name: "Toilet Lt1" },
                    { kode: "P00007", name: "HRD Recruitment" },
                    { kode: "P00008", name: "Profesional Room" },
                    { kode: "P00009", name: "Support" },
                    { kode: "P00010", name: "HRD Payroll" },
                    { kode: "P00011", name: "Meeting Audit" },
                    { kode: "P00012", name: "GM Support" },
                    { kode: "P00013", name: "IT Development" },
                    { kode: "P00014", name: "IT Support" },
                    { kode: "P00015", name: "Loyal Room" },
                    { kode: "P00016", name: "Desk Collection" },
                    { kode: "P00017", name: "Focus Room" },
                    { kode: "P00018", name: "GM Audit" },
                    { kode: "P00019", name: "GM Bisnis" },
                    { kode: "P00020", name: "Area Manager Fathur" },
                    { kode: "P00021", name: "Area Manager Faiz" },
                    { kode: "P00022", name: "Area Manager Indah" },
                    { kode: "P00023", name: "Area Manager Eka" },
                    { kode: "P00024", name: "Internal Audit" },
                    { kode: "P00025", name: "Bisnis" },
                    { kode: "P00026", name: "General Affair" },
                    { kode: "P00028", name: "Keuangan" },
                    { kode: "P00029", name: "GM General Affair" },
                    { kode: "P00030", name: "Wakil Direktur" },
                    { kode: "P00031", name: "Ruang CCTV" },
                ]
            }
            let getPengiriman = this.$axios.get("api/transaksi/pengiriman").then(response => {

                let datas = response.data.data

                for (var data of datas.data) {

                    let penerima = "-"
                    let pengirim = "-"
                    if (data.cabang_penerima != null) {
                        penerima = (data.cabang_penerima.name != null) ? data.cabang_penerima.name : data.cabang_penerima.nama_cabang
                    }
                    if (data.cabang_pengirim != null) {
                        pengirim = (data.cabang_pengirim.name != null) ? data.cabang_pengirim.name : data.cabang_pengirim.nama_cabang
                    }

                    this.pengiriman.push({
                        id: data.id,
                        noInvoice: data.no_invoice,
                        tanggal: data.tanggal,
                        pengirim: pengirim,
                        penerima: penerima,
                        totalBarang: data.total_unit,
                        flag: data.flag,
                        status: data.status,
                        status_approval: data.status_approval,
                        user_input: data.user_input

                    })
                }

                this.perPage = datas.per_page
                this.size = this.perPage
                this.from = datas.from
                this.limit = datas.to
                this.loading = false

                this.updateTotalItem(datas.total)
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)

            })

            Promise.all([getPengiriman]).then(() => {
                this.initStatus = 1
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)
                this.toggleBusy()
            })
                .catch(error => {
                    console.log(error)
                    this.initStatus = -1
                })
        },

        getPengiriman() {
            this.toggleBusy()
            this.pengiriman = []
            //console.log(this.filter.tipe);
            //console.log(this.filter.golongan);
            //if (this.filter.tipe != null) this.query = this.query + "&tipe=" + this.filter.tipe
            //if (this.filter.merk != null) this.query = this.query + "&id_kategori=" + this.filter.golongan

            this.$axios.get("api/transaksi/pengiriman" + this.query).then(response => {


                let datas = response.data.data
                console.log(datas.data);

                for (var data of datas.data) {

                    let penerima = "-"
                    let pengirim = "-"
                    if (data.cabang_penerima != null) {
                        penerima = (data.cabang_penerima.name != null) ? data.cabang_penerima.name : data.cabang_penerima.nama_cabang
                    }
                    if (data.cabang_pengirim != null) {
                        pengirim = (data.cabang_pengirim.name != null) ? data.cabang_pengirim.name : data.cabang_pengirim.nama_cabang
                    }
                    this.pengiriman.push({
                        id: data.id,
                        noInvoice: data.no_invoice,
                        tanggal: data.tanggal,
                        pengirim: pengirim,
                        penerima: penerima,
                        totalBarang: data.total_unit,
                        status: data.status,
                        user_input: data.user_input

                    })
                }


                this.perPage = datas.per_page
                this.size = this.perPage
                this.from = datas.from
                this.limit = datas.to
                this.loading = false

                this.toggleBusy()
                this.updateTotalItem(datas.total)
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)

            })
                .catch(error => {
                    console.log(error)
                    console.log(error.response.data.reason)
                    this.$helper.parseError(this, error)
                    this.toggleBusy()
                    this.loading = false
                })
        },

        prepare(modalToShow) {

            if (this.form.kategori == null) {
                return this.$helper.toastErr(this, "Mohon pilih Kategori!")
            }

            if (this.form.pengirim == null) {
                return this.$helper.toastErr(this, "Mohon pilih Pengirim!")
            }

            if (this.form.lokasi == null) {
                return this.$helper.toastErr(this, "Mohon pilih Lokasi!")
            }

            if (this.form.penerima == null) {
                return this.$helper.toastErr(this, "Mohon pilih Penerima!")
            }

            if (this.form.kurir == null || this.form.kurir == "") {
                return this.$helper.toastErr(this, "Mohon isi Kurir!")
            }


            if (this.form.keterangan == null || this.form.keterangan == "") {
                return this.$helper.toastErr(this, "Mohon isi Keterangan!")
            }
            this.$bvModal.show(modalToShow)

        },

        onSubmit() {
            this.form.tanggal = new Date().toJSON().slice(0, 10).replace(/-/g, '-')
            this.form.pengirim = this.form.pengirim.kode
            this.form.penerima = this.form.penerima.kode

            this.$axios.post('api/transaksi/pengiriman', this.form).then((response) => {
                this.form = {
                    pengirim: null,
                    lokasi: null,
                    penerima: null,
                    kurir: null,
                    kategori: null,
                    flag: 0,
                    status: 1,
                    keterangan: null,
                }
                this.valueKurir = null

                this.$bvModal.hide("m-create")
                this.$bvModal.hide("m-confirm-create")
                this.$helper.toastSucc(this, "Pengiriman berhasil ditambahkan!")

                this.getPengiriman()
            })
        },

        //----------------- Fungsi Pagination, Sorting, dan Filtering -----------------


        onPageChange() {
            if (this.loading == false) {
                console.log(this.currentPage);
                this.page = this.currentPage
                this.query = "?page=" + this.page

                this.getPengiriman()
            }
        },

        onLimitChange() {
            this.perPage = this.input.show
            this.onPageChange()
        },

        onFilter(letters) {

            if (this.filter == null) {
                this.$helper.toastErr(this, "Silakan isi nama jenis barang terlebih dahulu!")
            } else {
                let filteredArray = []

                for (var x of this.pengirimanAll) {
                    if (x.name.toLowerCase().includes(letters.toLowerCase())) {
                        filteredArray.push(x)
                    }
                }

                this.pengiriman = filteredArray

            }
        },

        onReset() {
            // this.filter = {
            //     jenis: null,
            //     golongan: null,
            // },

            this.page = 1
            this.query = "?page=" + this.page
            this.currentPage = 1
            this.loading = true
            this.getPengiriman()
        },


        //----------------- Fungsi Pindah Halaman dan Fungsi Lain -----------------

        toggleBusy() {
            if (this.isBusy == true) this.isBusy = false
            else this.isBusy = true
        },

        updateTotalItem(total) {
            this.perPage = this.size
            this.rows = total
            if (this.limit > this.rows) limit = this.rows
            this.totalViewText = "Menampilkan " + this.from + " - " + this.limit + " dari " + this.rows + " entri"
        },

        inputJenisBarang() {
            this.$router.push({ path: `${this.$route.path}/input` });
        },
    },

    watch: {

        //----------------- Pantau Kategori -----------------

        "form.kategori": function (newValue) {
            console.log(newValue)
            this.form.lokasi = null
            this.form.pengirim = null
            if (newValue == 1) {
                // Jika Cabang
                if (this.roleId == 56 || this.roleId == 5 || this.roleId == 4) {
                    this.lokasiOpt = [
                        { id: 2, name: "Kantor Cabang" },
                    ]
                }
                else {
                    // Jika Pusat
                    console.log("Pusat")
                    this.lokasiOpt = [
                        { id: 1, name: "Kantor Pusat" },
                        { id: 2, name: "Kantor Cabang" },
                    ]

                    this.pengirimOpt = [
                        { kode: "0999", name: "Gudang Pusat" },
                        { kode: "P00002", name: "Toilet Lt2" },
                        { kode: "P00003", name: "Toilet Lt3" },
                        { kode: "P00004", name: "Toilet Lt4" },
                        { kode: "P00005", name: "Toilet Lt1" },
                        { kode: "P00007", name: "HRD Recruitment" },
                        { kode: "P00008", name: "Profesional Room" },
                        { kode: "P00009", name: "Support" },
                        { kode: "P00010", name: "HRD Payroll" },
                        { kode: "P00011", name: "Meeting Audit" },
                        { kode: "P00012", name: "GM Support" },
                        { kode: "P00013", name: "IT Development" },
                        { kode: "P00014", name: "IT Support" },
                        { kode: "P00015", name: "Loyal Room" },
                        { kode: "P00016", name: "Desk Collection" },
                        { kode: "P00017", name: "Focus Room" },
                        { kode: "P00018", name: "GM Audit" },
                        { kode: "P00019", name: "GM Bisnis" },
                        { kode: "P00020", name: "Area Manager Fathur" },
                        { kode: "P00021", name: "Area Manager Faiz" },
                        { kode: "P00022", name: "Area Manager Indah" },
                        { kode: "P00023", name: "Area Manager Eka" },
                        { kode: "P00024", name: "Internal Audit" },
                        { kode: "P00025", name: "Bisnis" },
                        { kode: "P00026", name: "General Affair" },
                        { kode: "P00028", name: "Keuangan" },
                        { kode: "P00029", name: "GM General Affair" },
                        { kode: "P00030", name: "Wakil Direktur" },
                        { kode: "P00031", name: "Ruang CCTV" },
                    ]
                }

            }
            if (newValue == 2) {
                console.log("return")
                console.log(this.roleId);
                if (this.roleId != 56 && this.roleId != 5 && this.roleId && 4) {
                    console.log("return pusat");
                    this.pengirimOpt = [
                        { kode: "P00002", name: "Toilet Lt2" },
                        { kode: "P00003", name: "Toilet Lt3" },
                        { kode: "P00004", name: "Toilet Lt4" },
                        { kode: "P00005", name: "Toilet Lt1" },
                        { kode: "P00007", name: "HRD Recruitment" },
                        { kode: "P00008", name: "Profesional Room" },
                        { kode: "P00009", name: "Support" },
                        { kode: "P00010", name: "HRD Payroll" },
                        { kode: "P00011", name: "Meeting Audit" },
                        { kode: "P00012", name: "GM Support" },
                        { kode: "P00013", name: "IT Development" },
                        { kode: "P00014", name: "IT Support" },
                        { kode: "P00015", name: "Loyal Room" },
                        { kode: "P00016", name: "Desk Collection" },
                        { kode: "P00017", name: "Focus Room" },
                        { kode: "P00018", name: "GM Audit" },
                        { kode: "P00019", name: "GM Bisnis" },
                        { kode: "P00020", name: "Area Manager Fathur" },
                        { kode: "P00021", name: "Area Manager Faiz" },
                        { kode: "P00022", name: "Area Manager Indah" },
                        { kode: "P00023", name: "Area Manager Eka" },
                        { kode: "P00024", name: "Internal Audit" },
                        { kode: "P00025", name: "Bisnis" },
                        { kode: "P00026", name: "General Affair" },
                        { kode: "P00028", name: "Keuangan" },
                        { kode: "P00029", name: "GM General Affair" },
                        { kode: "P00030", name: "Wakil Direktur" },
                        { kode: "P00031", name: "Ruang CCTV" },
                    ]
                }
                this.lokasiOpt = [{ id: 1, name: "Kantor Pusat" }]
            }
        },

        "form.lokasi": function (newValue) {
            console.log(newValue);
            this.form.penerima = null
            if (newValue == 1) {
                console.log("Pusat")
                if (this.roleId == 56 || this.roleId == 5 || this.roleId == 4) {
                    this.penerimaOpt = [
                        { kode: "0999", name: "Gudang Pusat" },
                    ]
                } else {
                    this.penerimaOpt = [
                        { kode: "0999", name: "Gudang Pusat" },
                    ]
                }
            }

            if (newValue == 2) {
                console.log("Cabang")
                if (this.roleId == 56 || this.roleId == 5 || this.roleId == 4) {
                    let getCabang = localStorage.getItem("cabang")
                    console.log(getCabang);
                    this.penerimaOpt = JSON.parse(getCabang)
                }
                else {
                    this.$axios.get('api/admin/cabang/all').then(response => {
                        console.log(response.data.data);
                        let datas = response.data.data
                        this.penerimaOpt = datas
                    })
                }
            }

        },

        "form.pengirim": function (newVal) {
            console.log(this.form.pengirim);
        }


    },

    created() {
        this.init()
    }
}
</script>
